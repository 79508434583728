.RootMenu {
	padding: 0px;
	border: none;
	font-size: 16px;
	background: none;
	border-radius: 6px;
	border: 1px solid var(--active-unfocused);
}

.RootMenu[data-open],
.RootMenu:hover {
	background: var(--active-unfocused);
}

.Menu {
	background: rgba(255, 255, 255, 0.8);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	padding: 4px;
	border-radius: 6px;
	box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
	outline: 0;
	z-index: 100;
}

.MenuItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: none;
	width: 100%;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	text-align: left;
	line-height: 1.8;
	min-width: 110px;
	margin: 0;
	outline: 0;
}

.MenuItem:focus {
	background: var(--highlighted);
	color: white;
}

.MenuItem[data-nested][data-open]:not([data-focus-inside]) {
	background: var(--highlighted);
	color: white;
}

.MenuItem[data-focus-inside][data-open] {
	background: var(--active-unfocused);
}
